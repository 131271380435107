<template>
    <div class="py-1 px-3">

        <div class="h-100 flex-fill d-flex flex-column">
            <h3 class="text-center font-weight-bolder text-white mt-2">Adoption Interview Summary</h3>

            <div class="" v-for="section in sections">
                <h3 class="text-white font-weight-bolder h4 mt-2">{{section.title}}</h3>
                <div class="w-100 mb-1" style="height:2px;background-color: #FFAD32;"></div>
                <div class="d-flex flex-column" style="gap:5px">
                    <div class="text-white w-100 mb-1" v-for="field in section.fields">
                        <div class="h5 text-white">{{ field.label }}</div>
                        <div>
                            {{ datas[field.key] }}
                        </div>
                    </div> 
                </div>
            </div> 

            <button class="btn text-white flex-fill mt-2" @click="nextStep()"
                style="background-color: #187294;height:50px;font-size: 18px;">
                Thank You
            </button>
        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    methods: {
        nextStep() {
            this.$router.push('/shelter/adopt/'+this.shelter_id)
        }
    }, 
    async mounted(){
        let temp = await store.dispatch('shelter/GetDetailAdoption', this.id)
        this.shelter_id = temp.shelter_id
        this.datas = JSON.parse(temp.datas)
    },
    data() {
        return {
            datas: {},
            shelter_id : null,
            sections: [
                {
                    title: 'Step 1 - Fill Personal Information', 
                    fields: [
                        { key: 'name', label: 'Name' },
                        { key: 'age', label: 'Age (Years)' },
                        { key: 'address', label: 'Address' },
                        { key: 'occupation', label: 'Occupation' },
                        { key: 'mobile_number', label: 'Mobile Number' },
                    ],
                },
                {
                    title: 'Step 2 - Give Your Reason for Adopting', 
                    fields: [
                        { key: 'reason_adopting', label: 'Why are you interested in adopting me?' },
                        { key: 'is_first_pet', label: 'Is this your first pet?' },
                        { key: 'is_other_pet', label: 'Do you have other pets in the home?' },
                        { key: 'is_have_vet', label: 'Do you already have a veterinarian?' },
                        { key: 'what_plan_to_pet', label: 'What do you plan on doing with your pet?' },
                    ],
                },
                {
                    title: 'Step 3 - Describe Your Housing Capability', 
                    fields: [
                        { key: 'type_of_housing', label: 'Type of housing' },
                        { key: 'ownership_of_house', label: 'Ownership of house' },
                        { key: 'is_have_backyard', label: 'Do you have a backyard or areas for pet?' },
                        { key: 'how_many_adults', label: 'How many adults present in your home?' },
                        { key: 'how_many_childrens', label: 'How many minor children present in your home?' },
                        { key: 'anyone_have_allergies', label: 'Does anyone in the home have allergies to pet?' },
                        { key: 'where_pet_spend_time', label: 'Where will the pet spend most of its time?' },
                        { key: 'inside_or_outside_pet', label: 'Will your pet be inside pet or outside pet?' },
                        { key: 'how_long_pet_left', label: 'How many hours will the pet left alone?' },
                    ],
                },
                {
                    title: 'Pledge and What to Expect', 
                    fields: [
                        { key: 'additional_info', label: 'Notes' }, 
                    ],
                },
            ],
            id: this.$route.params.id
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

input:focus {
    color: white !important;
}

.input-group span {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    font-size: 24px;
    color: #1F9BCD;
    background-color: transparent;
}

.input-group input {
    margin-left: 0;
    padding-left: 0;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    height: 50px;
    color: white;
    background-color: transparent !important;
}
</style>   	